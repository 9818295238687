<template>
  <div>
    <b>Dashboard</b>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>
